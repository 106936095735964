module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cloud8Ops Test","short_name":"Cloud8Ops Test","start_url":"/","background_color":"#fff","theme_color":"#333333","display":"standalone","icon":"static/logos/cloud8-logo-mobile-demo.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
